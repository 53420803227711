main.homepage {
  position: relative;
}

main.homepage section {
  position: relative;
  text-align: center;
  width: 100%;
  min-height: 300px;
  position: relative;
}

main.homepage {
  header {
    padding: 100px 0 200px;
    text-align: center;
    background: var(--red);
    border: 10px solid #fff;

    h1 {
      font-size: 36px;
      line-height: 1em;
      margin-top: 0.5em;
      margin-bottom: 0.25em;
      color: var(--white);
    }

    p {
      font-size: 18px;
      color: var(--white);
    }

    .button {
      width: 85%;
      margin-top: 20px;
      margin: 50px auto 20px;
    }

    & + .yellow-blob {
      margin-top: -133px;
    }
  }

  .button {
    max-width: 340px;
  }

  .section-emoji {
    min-width: 88px;
    min-height: 88px;
  }

  section {
    p {
      margin: 1em auto;
      line-height: 1.25em;
    }
    .section-emoji {
      min-width: 88px;
      min-height: 88px;
    }
  }
  .yellow-blob,
  .green-blob {
    width: 100%;
    height: 300px;
    position: relative;
    z-index: 1;
  }

  .section-2 {
    margin-top: -120px;
    padding: 160px 0 160px;
    background: var(--blue);
    color: var(--white);
    border: 10px solid #fff;
    box-sizing: border-box;

    .props {
      text-align: center;
      width: 90%;
      margin: auto;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
    .prop {
      margin: auto;
      margin-bottom: 50px;
      flex-basis: 100%;
    }
    & + .green-blob {
      margin-top: -133px;
    }
  }
  .button {
    margin: auto;
  }
}

@media screen and (min-width: 1024px) {
  main.homepage .section-2 .prop {
    flex-basis: auto;
  }
}
